<template>
  <ZMainPage type="card">
    <div class="box-info">
      <div class="z-box-bg mb-2">
        <el-button type="primary" @click="handleEvaluate">我要评价</el-button>
      </div>
      <div class="z-box-bg top-info mb-2">
        <div class="top-info-item" v-for="item in list" :key="item.label">
          <div class="top-info-label">{{ item.label }}：</div>
          <div class="top-info-value">
            <span :class="item.class">{{ item.value }}</span>
            <span class="text-primary ml-2 c-p" v-if="item.button" @click="handleView(item.type)">{{ item.button }}</span>
          </div>
        </div>
      </div>
      <div class="bg-white flex-1 pb-2">
        <list-template
          :loading="loading"
          :total="total"
          :table-data="tableData"
          :table-config="tableConfig"
          @onHandle="handleView"
          @onChangePage="changeCurrentPage"
        ></list-template>
      </div>
      <Dialog :visible.sync="visible" :title="title" showLine submitText="提交" :beforeSubmit="onBeforeClose" :show-footer="showFooter" :closeOnClickModal="closeOnClickModal">
        <template v-if="showFooter">
          <LineWrapper labelWidth="80rem" labelColor="#222">
            <LineItem :label="item.label" v-for="item in showList" :key="item.id">{{ item.value }}</LineItem>
          </LineWrapper>
          <el-form ref="form" :model="formData" :rules="rules" label-position="top" label-width="120rem">
            <el-form-item prop="content">
              <el-input type="textarea" v-model="formData.content" placeholder="请输入对此学生的评价" :autosize="{ minRows: 8, maxRows: 20}" :maxlength="300" show-word-limit></el-input>
            </el-form-item>
          </el-form>
        </template>
        <template v-else>
          <LineWrapper labelWidth="90rem" labelColor="#222">
            <LineItem :label="item.label" v-for="item in showList" :key="item.id" :vertical="item.vertical" :valueStyle="item.valueStyle">{{ item.value }}</LineItem>
          </LineWrapper>
        </template>
      </Dialog>
    </div>
  </ZMainPage>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import LineWrapper from "@/components/common/LineWrapper";
import LineItem from "@/components/common/LineItem";
import http, { tableList } from '@/api'
export default {
  _config:{'route':{'path':'comprehensive-quality','meta':{'title':'综合素质评价'}}},
  components: { Dialog, LineWrapper, LineItem },
  data() {
    return {
      loading: false,
      page: 1,
      total: 0,
      pageCount: 0,
      tableData: [],
      tableConfig: [
        { prop: 'teacher_name', label: '教师姓名' },
        { prop: 'subject_name', label: '教学科目' },
        { prop: 'content', label: '评价内容' },
        { prop: 'created_at', label: '评价时间' },
        { handle: true, width: '120rem', label: '操作', render:() => ['详情'] }
      ],
      list: [],
      visible: false,
      closeOnClickModal: true,
      showFooter: true,
      id: '',
      title: '',
      formData: { content: '' },
      rules: {
        content: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      showList: [],
      info: {}
    }
  },
  created() {
    this.getData()
    this.getList()
  },
  methods:{
    changeCurrentPage(e) {
      this.page = e;
      this.getList();
    },
    getData() {
      http({ url: '/student/details/base-info', pre: 'api2' }, { student_id: this.$route.query.id }).then(res => {
        const { base = {} } = res
        this.info = base
        this.list = [
          { label: '学生姓名', value: base.student_name },
          { label: '学号', value: base.student_no },
          { label: '行政班', value: base.class_name },
          { label: '班主任', value: base.teacher_name },
        ]
      })
    },
    getList() {
      let params = { page: this.page, student_id: this.$route.query.id }
      this.loading = true
      tableList('/api2/api/student/details/evaluate', params).then(res => {
        const { list, pageCount, total } = res
        this.total = total
        this.pageCount = pageCount
        this.tableData = list
      }).finally(() => {
        this.loading = false
      })
    },
    handleEvaluate() {
      this.title = '撰写综合素质评价'
      this.showList = [
        { label: '学生姓名：', value: this.info.student_name },
      ]
      this.formData.content = ''
      this.showFooter = true
      this.closeOnClickModal = false
      this.visible = true
    },
    handleView(row) {
      this.title = `${this.info.student_name}的综合素质评价`
      this.showList = [
        { label: '评价教师', value: row.teacher_name || '-' },
        { label: '教学科目', value: row.subject_name || '-' },
        { label: '评价时间', value: row.created_at },
        { label: '评价内容', value: row.content, valueStyle: { flex: 1, color: '#686868', backgroundColor: '#F6F6F6', border: 'solid 1px #D8D8D8', padding: '14rem', borderRadius: '6rem'} }
      ]
      this.showFooter = false
      this.closeOnClickModal = true
      this.visible = true
    },
    onBeforeClose(done, instance) {
      this.$refs.form.validate((valid) => {
        if(valid) {
          instance.confirmButtonLoading = true
          instance.confirmButtonText = '保存中...'
          http({ url: '/student/details/evaluate-add', method: 'post', pre: 'api2' }, { content: this.formData.content, student_id: this.$route.query.id }).then(() => {
            this.$message.success('提交成功')
            this.getList()
            done()
          }).catch(() => {
            instance.confirmButtonLoading = false
            instance.confirmButtonText = '确定'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box-info {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.top-info {
  display: grid;
  gap: 10rem 20rem;
  grid-template-columns: repeat(auto-fill, minmax(320rem, 1fr));
  &-item {
    font-size: 16rem;
    line-height: 24rem;
    display: flex;
    flex-shrink: 0;
  }
  &-label {
    width: 100rem;
    font-weight: 600;
  }
}
</style>
